import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { filter } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
import { useTranslation } from 'react-i18next'; //Multi Language
//import formatDateTime from '../../../src/utils/formatDateTime';
import _ from 'lodash';

// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton,
  TableContainer, TablePagination, InputLabel, FormControl, Select, Box, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, TableHead, Collapse
} from '@mui/material';
import { SvgIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import InsightsIcon from '@mui/icons-material/Insights';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import { ReactComponent as Icon_Extract } from '../../assets/icons/extract.svg';
import { ReactComponent as Icon_Transaction } from '../../assets/icons/transaction.svg';

import Scrollbar from '../../components/scrollbar'
// sections
//import { StudentListHead, StudentListToolbar } from '../../sections/@dashboard/student';
import { RegisteredStudentListHead, RegisteredStudentListToolbar } from '../../sections/@dashboard/finance';

//database
import { getRegisteredStudents } from '../../_data/finance/getRegisteredStudent';
import ReportStudentAttendances from '../../_data/student/getReportParticipant';
import SessionExpiredDialog from '../../components/session/sessionExpried';
import DataDisplay from '../student/CustomDataDisplay';
import { getBatchNames } from '../../_data/batchs/getBatchNames';
import CreateStudentDialog from '../../components/student/create-student-dialog';
import GetCourseCoordinatorTasks from '../../components/course-coortination/coursecoordinationtasks';
import UpdateRegisteredStudentDialog from '../../components/student/update-student-dialog';
import StudentDetailsDialog from '../../components/student/student-details-dialog';
import DeleteStudent from '../../_data/student/deleteStudent';
import getSession from '../../utils/getSession'; //Session Management
import clearSession from '../../utils/clearSession';
import getNextTransactions from '../../_data/student/getNextTransactions';
import getFirstStudentRecords from '../../_data/student/getFirstStudentRecords';
import updateChangeFirstMail from '../../_data/student/updateFirstMail';
//import getCoordinatorsTotalTasks from '../../_data/student/getCoordinatorsTotalTasks';
import AssignStudentDialog from '../../components/finance/assign-student';
import FinanceContractDetails from '../../components/finance/financialContractDetails';
import FinancePaymentDetails from '../../components/finance/financialPaymentDetails';

import config from '../../config';
import { red } from '@mui/material/colors';
const { PUBLIC_URL } = config;
const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filterStatusId, filterBatchName, filterCoordinatorId, filterCallInDays, filterFirstRecord, nextTransactions) {
  if (!Array.isArray(array)) {
    return [];;
    //throw new Error('Data is not an array');
  }
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]).filter((student) => {
    // Genel arama sorgusu için filtreleme
    const lowerCaseQuery = String(query).toLowerCase(); // query'yi string'e çevir   
    const matchQuery = query ?
      Object.values(student).some((value) => String(value).toLowerCase().includes(lowerCaseQuery)) :
      true;
    // Status ID'ye göre filtreleme
    const matchStatus = filterStatusId ? student.status === filterStatusId : true;
    // Batch Name'e göre filtreleme
    const matchBatch = filterBatchName ? student.coursename === filterBatchName : true;
    // Course Coorsinators'e göre filtreleme
    const matchCoordinators = filterCoordinatorId ? student.follower === filterCoordinatorId : true;
     // Course nextTransactions'e göre filtreleme
    const matchCallInDays = nextTransactions && nextTransactions.length > 0
    ? nextTransactions.includes(student.idstudents)
    : true;
    return matchQuery && matchStatus && matchBatch && matchCoordinators && matchCallInDays;
  });
};


export default function RegisteredStudentsPage() {
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();

  // -------------------------COLUMN TITLES-------------------------------------------------- -----

  const TABLE_HEAD = [
    { id: 'namesurname', label: t('NameSurname'), alignRight: false },    
    { id: 'resourcecode', label: t('Finance Status'), alignRight: false },
    { id: 'status', label: t('Academic Status'), alignRight: false },
    { id: 'coursename', label: t('CourseName'), alignRight: false },
    { id: 'contact', label: t('Contact'), alignRight: false },
    { id: 'follower', label: t('Follower'), alignRight: false },
    { id: '' },
  ];
  //TODO: id degerini dogru yazmak tablo sutununda siralama yapmasi icin cok onemli. Eger databseden gelen column name ile ayni olmaz ise islem yapmaz.

  // ---------------------------------------------------------------------------------------------------------

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('coursename');
  const [filterStudentId, setfilterStudentId] = useState('');
  const [filterStatusId, setfilterStatusId] = useState('');
  const [filterBatchName, setfilterBatchName] = useState('');
  const [filterCoordinatorId, setfilterCoordinatorId] = useState('');
  const [filterCallInDays, setfilterCallInDays] = useState(false);
  const [filterFirstRecord, setfilterFirstRecord] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [studentsDatas, setStudentsDatas] = useState([getStudents]);
  const [studentsDatas, setStudentsDatas] = useState([]);
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectData, setSelectData] = useState();
  const [selectedBatch, setSelectedBatch] = useState("");
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");
  const [nextTransactions, setNextTransactions] = useState([]);
  const [firstStudentRecords, setFirstStudentRecords] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [newTotalTasks, setNewTotalTasks] = useState(0);
  const [isFinanceRole, setIsFinanceRole] = useState(false);
  const navigate = useNavigate();
  


  const MENU_OPTIONS = [
    { label0: 'AssignFollower ', },
    { label1: 'Edit ', },
    { label2: 'Delete ', },
    { label3: 'AllExtract ', },
    { label4: 'Attendance', },
    { label5: 'Finance', },
    { label6: 'Details', },
  ];

  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      selected.forEach((item) => {
      });
      if(selected.length > 0){
        switch(label){   
          case t('AssignStudent'):
            checkFinanceRole();
           // console.log("isFinanceRole, Follower: ", isFinanceRole, selected[0]?.id);
            // console.log("checkFinanceRole: ", checkFinanceRole);
            if(!isFinanceRole){                
              setSeverity('error');
              setAlertMessage('Statu assignment procedures can only be carried out by individuals authorized to act as the Finance Role!');
              setMsgOpen(true);
            } else{
              handleFirstTaskDialog(selected);
            //   setSeverity('success');
            //   setAlertMessage('You ok. You can Finance Role!');
            //   setMsgOpen(true);
            }
            break;
            case t('FinancialTracking'):          
                // setSeverity('success');
                // setAlertMessage(`Selected id: ${selected[0].id}`);
                // setMsgOpen(true);
                handlePaymentRowClick(null, selected[0].id);
                handleFinancialTracing(selected[0].id);
            break;
          case 'Edit':            
            break;
          case t('Details'):
            setSelectedStudentData(selected);
            handleStudentDetails(selected);
            break;
          case t('Delete'):
            handleConfirmDialogOpen(selected);
            break;
          
          case ('AllExtract'):
            setSeverity('warning');
            setAlertMessage('All connections are closed.');
            setMsgOpen(true);
            //handleConfirmExtractDialogOpen(selected);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  }; 
  
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const handleSessionExpiration = () => {
    setIsSessionExpired(true);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    //console.log('Snackbar kapatılıyor');
    setMsgOpen(false);
  };
  
//   const handleStudentAttendancePageOpen = async (email) => {
//     const reportStudentZoomAttendances = await ReportStudentAttendances(email);  
//     const MeetItem = async (index) => {
//       if (index < selected.length) {
//         navigate(`/dashboard/studentattendances`, { state: { reportStudentZoomAttendances } });
//         await MeetItem(index + 1);
//       }
//     };
//     await MeetItem(0);
//   }

//   const handleInterviewTransactionPageOpen = async (selectedId) => {
//     const TransactionItem = async (index) => {
//       if (index < selected.length) {
//          navigate(`/dashboard/interviewtransaction`, { state: { selectedId } });
//         await TransactionItem(index + 1);
//       }
//     };
//     await TransactionItem(0);
//   }

  const handleAllBatchAttendancePageOpen = async () => {
    const batchName = filterStudentId;
    if(filterRecords.length > 0){
      const getEmails = filterRecords.map((selected, index) => {
        return selected.contact.mail['mailaddress-1'];
      });
      const reportAllBatchZoomAttendances = await ReportStudentAttendances(getEmails);   
      navigate(`/dashboard/studentattendances`, { state: { reportAllBatchZoomAttendances, batchName } });
    }
  };

  const handleSelectionChange = (value) => {
    setSelectedBatch(value);
    setPage(0);
    setfilterStudentId(value);    
  };

  const session = getSession();
  const dynamicStructure = session && session.AllRoles ? session.AllRoles : [];

  const checkFinanceRole = () => {
    session.user[0].roles.Roles.some((role) => {
      if (role === "Finance") {        
        setIsFinanceRole(true);
        return true;
      } else {
        setIsFinanceRole(false);
        return false;
      }
    });
  };

  const getstudentsDatas = async () => {
    try {
      showLoading();      
      checkFinanceRole();
      const data = await getRegisteredStudents();
      //console.log('getRegisteredStudents: ', data);
      setrecordsNumber(data.length);
      setStudentsDatas(data);
      setOriginalData(data);
    } catch (error) {
        console.error(error);
        setOriginalData([]);
        setStudentsDatas([]);
    }finally{
      hideLoading();
    }
  };

  const loadNextTransactions = async () => {
    try {
       const response = await getNextTransactions();
       setNextTransactions(response);
    } catch (error) {
        console.error('Error loading loadNextTransactions:', error);
    }
  };

  const loadFirstStudentRecords = async () => {
    try {
       const response = await getFirstStudentRecords();
       setFirstStudentRecords(response);
    } catch (error) {
        console.error('Error loading firstStudentRecords:', error);
    }
  };

  const filterStudentsCallInDays = () => {
    if (filterCallInDays) {
      const filtered = originalData.filter((student) => nextTransactions.includes(student.idstudents));
      setStudentsDatas(filtered);
    } else {
      setStudentsDatas(originalData);
    }
  };
  
  const filterStudentsFirstRecord = () => {
    try {      
      showLoading();
      if (filterFirstRecord) {
        const filtered = originalData.filter((student) => firstStudentRecords.includes(student.idstudents));
        setStudentsDatas(filtered);
      } else {
        setStudentsDatas(originalData);
      }      
    } catch (error) {
      console.log("[Error]-filterStudentsFirstRecord: ", error);
    }finally{     
      hideLoading();
    }
  };

  const [students, setStudents] = useState([]);
  const handleNewStudentAdded = async(message) => {
    try {
      if(message === "Finance installment record process is successful."){
        setSeverity('success');
        setAlertMessage(message);
        setMsgOpen(true);
        getstudentsDatas();
      }      
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const updateDialogRef = useRef();
  const [selectedStudentData, setSelectedStudentData] = useState(null);
  const handleEditStudent = () => {
    try { 
      setSelectedStudentData(selected);
      if (updateDialogRef.current) {
        updateDialogRef.current.handleOpen();
      }
    } catch (error) {
      console.log("error: ", error);;      
    }
  };

  const studentDetailsDialogRef = useRef();
  const handleStudentDetails = (getSelected) => {
    try { 
      setSelectedStudentData(getSelected[0]);
      if (studentDetailsDialogRef.current) {
        studentDetailsDialogRef.current.handleOpen();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };  

  useEffect(() => {
    getstudentsDatas();
  }, []);
  
  // useEffect(() => {
  //   if (filterCallInDays) {
  //     loadNextTransactions();
  //   } else {
  //     filterStudentsCallInDays();
  //   }
  // }, [filterCallInDays]);

  // useEffect(() => {
  //   if (filterFirstRecord) {
  //     loadFirstStudentRecords();
  //    } else {
  //     filterStudentsFirstRecord();
  //   }
  // }, [filterFirstRecord]);

  // useEffect(() => {
  //   filterStudentsCallInDays();
  // }, [nextTransactions]);
  
  // useEffect(() => {
  //   filterStudentsFirstRecord();
  //   //getNewTotalTasks();    
  // }, [firstStudentRecords]);


//   const getNewTotalTasks = async() => {
//     // TODO: Burada kayit yenilenince task sonuclarini coursecoorsinationtasks sayfasinda yeniden cekmeliyiz!!!
//     // try {      
//     //   const data = await getCoordinatorsTotalTasks();
//     //   setNewTotalTasks(data.length);
//     //   //setStudentsDatas(data);
//     // } catch (error) {
//     //   console.log("error: ", error);
//     // }
//   };
  
  const [filterRecords, setFilterRecords] = useState();

  useEffect(() => {
    let filteredStudents;
    if(nextTransactions.length > 0 && firstStudentRecords.length > 0) {
      filteredStudents = applySortFilter(studentsDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filterBatchName, filterCoordinatorId, nextTransactions, firstStudentRecords );
    } else if(firstStudentRecords.length > 0 && nextTransactions.length <= 0) {
      filteredStudents = applySortFilter(studentsDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filterBatchName, filterCoordinatorId, firstStudentRecords );
    } else if(nextTransactions.length > 0 && firstStudentRecords.length <= 0) {
      filteredStudents = applySortFilter(studentsDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filterBatchName, filterCoordinatorId, nextTransactions );
    }
    else {
      filteredStudents = applySortFilter(studentsDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filterBatchName, filterCoordinatorId);
    }
    const validFilteredStudent = filteredStudents || [];
    setrecordsNumber(validFilteredStudent.length);
    setFilterRecords(validFilteredStudent);
  }, [studentsDatas, order, orderBy, filterStudentId, filterStatusId, filterBatchName, filterCoordinatorId, filterCallInDays, filterFirstRecord, nextTransactions]);
  
  // --------------- Edit user Button & Modals
  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const handleOpenMenu = (event, { idstudents, idstudent_payment_contract, email, dateofbirth, name, surname, contact, follower, follower_namesurname, graduation, 
    memberships, coursename, status, resourcecode, Interninfo, allreferences, description, idbatch, langlevel, payment_contract_details }) => {
    setOpen(event.currentTarget);
    setSelected([{ id: idstudents, contractid: idstudent_payment_contract, mail: email, dateofbirth: dateofbirth, name: name, surname: surname, contact: contact, 
    follower: follower, follower_namesurname: follower_namesurname, graduation: graduation, memberships: memberships, 
    coursename: coursename, status: status, resourcecode: resourcecode, Interninfo: Interninfo, allreferences: allreferences,
    description: description, idbatch: idbatch, langlevel: langlevel, payment_contract_details }]);
    setSelectedUserName(email);
    setSelectedFullName(`${name} ${surname}`);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (!Array.isArray(filteredStudents)) {
        return;
      }
      const newSelecteds = filteredStudents.map((n) => ({
        id: n.idstudents,
        mail: n.email,
        contact: n.contact,
        fullname: `${n.name} ${n.surname}`,
      }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, student) => {
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }  
    setSelected(newSelected);
  };

  const refreshStudentData = () => {
    getstudentsDatas();
  };

  const performAction = async () => {
    try {
      const ids = currentAction.map((data, index) => {
        return data.id;      
      });
      const idsQueryString = ids.join(',');
      const response = await DeleteStudent(idsQueryString);
      //console.log('response.message: ', response.message);
      if (response.message === 'Student deleted successfully.' && response.affectedRows > 0) {
        setSeverity('success');
        setAlertMessage('Student deleted successfully.');
        setMsgOpen(true);
        getstudentsDatas();
        handleCloseMenu();
      }else if(response.message === 'Cannot delete or update a parent row, because there is dependency child records.'){
        setSeverity('warning');
        setAlertMessage(response.message);
        setMsgOpen(true);
        handleCloseMenu();
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  const openTransactionDetails = (follower) => {
   // console.log("idstudent-transaction: ", follower);
    // window.location.href = `mailto:${emailAddress}`;
    //window.open("https://mail.google.com/mail/?tab=rm&authuser=0&ogbl", '_blank');
  };
  
  const handleConfirm = () => {    
    performAction(currentAction);
    handleConfirmDialogClose();
  };

//   const handleConfirmExtract = () => {    
//     handleConfirmExractDialogClose();
//   };
  
//   const handleConfirmExtractDialogClose = () => {
//     setOpenConfirmDialog(false);
//   };

//   const handleConfirmExractDialogClose = () => {
//     setOpenExtractConfirmDialog(false);
//   };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
   const [currentAction, setCurrentAction] = useState();
   const handleConfirmDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenConfirmDialog(true);
  };

//   const [openConfirmExtractDialog, setOpenExtractConfirmDialog] = useState(false);
//   const handleConfirmExtractDialogOpen = (select) => {    
//     setCurrentAction(select);
//     setOpenExtractConfirmDialog(true);
//   };

  const [openAssignStudentExtractDialog, setOpenAssignStudentExtractDialog] = useState(false);
  const handleAssignStudentDialogOpen = () => {   
    setOpenAssignStudentExtractDialog(true);
  };

  const handleFirstTaskDialogClose = () => {    
   // console.log("onClose geldi...");   
    setOpenAssignStudentExtractDialog(false);
    refreshStudentData();
  };
  
  const [firstRecordStudentData, setFirstRecordStudentData] = useState({
    nameSurname: '',
    id: '',
    email: '',
    paymentDetails: '',
    contractId: '',
  });

  const handleFirstTaskDialog = (select) => {
    setFirstRecordStudentData({
      nameSurname: `${select[0].name} ${select[0].surname}`,
      id: select[0].id,
      email: select[0].mail,
      paymentDetails: select[0].payment_contract_details,
      contractId: selected[0].contractid
    });
    handleAssignStudentDialogOpen();
  };

  const handleFinancialTracing = (selectedId) => {
    //console.log("select[0].payment_contract_details: ", select[0].payment_contract_details);
    // setFirstRecordStudentData({
    //   nameSurname: `${select[0].name} ${select[0].surname}`,
    //   id: select[0].id,
    //   email: select[0].mail,
    //   paymentDetails: select[0].payment_contract_details
    // });
    handleFinancialTracingRowOpen();
  };
  const [openFinancialTracingExtractRow, setOpenFinancialTracingExtractRow] = useState(false);
  const handleFinancialTracingRowOpen = () => {   
    setOpenFinancialTracingExtractRow(true);
  };

  const handleFinancialTracingRowClose = () => {       
    setOpenFinancialTracingExtractRow(false);
    refreshStudentData();
  };


  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };

  const handleFilterStatusByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterStatusId('');
      return;
    }
    setPage(0);
    setfilterStatusId(newValue.id);
  };

  const handleFilterBatchByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterBatchName('');
      return;
    }
    setPage(0);
    setfilterBatchName(newValue.id);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - studentsDatas.length) : 0;
  const filteredStudents = applySortFilter(studentsDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filterBatchName, filterCoordinatorId, nextTransactions);
  const isNotFound = Array.isArray(filteredStudents) && !filteredStudents.length && (!!filterStudentId || !!filterStatusId || !!filterBatchName || !!filterCoordinatorId || !!nextTransactions);
  const validFilteredStudents = Array.isArray(filteredStudents) ? filteredStudents : [];
  const filteredStatuses = applySortFilter(studentsDatas, getComparator(order, orderBy), filterStatusId, filterBatchName, filterCoordinatorId, nextTransactions);
  const [selectedCoordinatorId, setSelectedCoordinatorId] = useState(null);

  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    };
    // Cut T ve Z char
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 16);
    return formattedDate;
  };

  const [openRowId, setOpenRowId] = useState(null);

  const handleRowClick = (rowId) => {
    setOpenRowId((prev) => (prev === rowId ? null : rowId));
  };

  const [openPaymentRowId, setOpenPaymentRowId] = useState(null);

  const handlePaymentRowClick = (event, rowId) => {
    event.stopPropagation(); // Event propagation'ı durdur
    setOpenPaymentRowId((prev) => (prev === rowId ? null : rowId));
  };

  return (
    <>    
      <Helmet>
        <title> {t('Page.Title')} | Oak Academy </title>
      </Helmet>     
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>          
          <div>
            <Typography variant="h4" gutterBottom>
              <span style={{ fontWeight: 'bold', fontSize: '25px', color: 'red' }}>{t('PreRegisteredStudentList')}</span>
              {/* // TODO: On kayitli ogrenci yani listede Prospective statusunde kayit kalmadiginda bu satiri mavi renkle boya!! ve {t('PreRegisteredStudentList')} yerine {t('RegisteredStudentList')} olsun. */}
              &nbsp;&nbsp;
              <span style={{ fontStyle: 'normal', fontSize: '15px', color: 'gray' }}> { t('TotalRecords')} {`: ${ recordsNumber } `}</span>
            </Typography>            
          </div>
        </Stack>
        <Card>
          <RegisteredStudentListToolbar 
            numSelected={selected.length} 
            filterName={filterStudentId} 
            onFilterName={handleFilterByName} 
            onStatusName={handleFilterStatusByName} 
            onBatchName={handleFilterBatchByName}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <RegisteredStudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                {                  
                  (rowsPerPage === -1 ? validFilteredStudents : validFilteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) //TODO: Eger veri gelmez ise kontrol yapip frontend tarafina sirayet ettirmeyelim.
                    .map((row, index) => {
                      const { idstudents, name, surname, acknowledged, idassessmentlevel, assessmentlevelname, dateofbirth, graduation, edulevelname, 
                        edulevelname_de, status, status_name, status_description, follower, firstmeetingemail, follower_namesurname, createdby, created_iduser, 
                        created_name_surname, idbatch, coursename, idteachable, email, langlevel, langname, langlevelname, occupation, occupationname, allreferences, 
                        contact, memberships, resourcecode, interninfo, description, atcreated, bycreated, atupdated, byupdated, paymentstatus, updatedrows, 
                        futurecontactdate, idstudent_payment_contract, contractdate, agreement_statusid, studentid, payment_contract_details, createdat, 
                        updatedat, avatarUrl } = row;                                      
                      const selectedStudent = selected.some((item) => item.id === idstudents);
                      const isRowOpen = openRowId === idstudents;
                      const isPaymentRowOpen = openPaymentRowId === idstudents;
                    return (
                    <React.Fragment key={index}>
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedStudent}                      
                        sx={{                       
                          backgroundColor: status === "Online Registered" ? 'lightblue' : 'inherit'
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedStudent}
                            onChange={(event) => handleClick(event, { id: idstudents, contractid: idstudent_payment_contract, mail: email ,contact: contact, follower: follower, 
                              follower_namesurname: follower_namesurname, membership: memberships, resourcecode: resourcecode, Interninfo: interninfo, 
                              idbatch: idbatch, langlevel:langlevel, allreferences: allreferences, assessmentlevelname: assessmentlevelname, 
                              description: description, fullname: `${name} ${surname}` })}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                             <Avatar alt={String(idstudents)} src={avatarUrl} /> 
                            <Typography variant="subtitle2" noWrap>
                              {`${name} ${surname}`}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{pl:7}}>
                            {resourcecode}
                              <Tooltip title="Finance Payment Details">
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => handleRowClick(idstudents)} // Set the open row ID on click
                                    sx={{
                                      '&:hover': {
                                        backgroundColor: '#ff9800',
                                      },
                                    }}
                                >
                                  {isRowOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                              </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          <Label
                          sx={{ 
                            color:
                              status == 1 ? 'success.main' : 
                              status == 3 ? 'warning.main' : 
                              status == 4 ? 'primary.main' : 
                              status == 5 ? 'error.main' : 
                              status == 6 ? 'mycolor.main' :
                              status == 7 ? 'info.main' 
                              : 'error'
                            }}>
                            { //TODO: These values must be retrieved from the database and get must dynamic .
                              status == 1 ? t('RegisteredStudent') : 
                              status == 2 ? t('InternStudent') :  
                              status == 3 ? t('TimedAccessGranted') :
                              status == 4 ? t('ProspectiveStudent') : 
                              status == 5 ? t('RelationshipEnded') :
                              status == 6 ? t('LMS-HybridRegistered') : 
                              status == 7 ? t('LMSRegistered') : t('RelationshipEnded')
                            }
                          </Label>
                        </TableCell>
                        <TableCell align="left">{coursename}</TableCell>
                        <TableCell align="left">
                          <DataDisplay customData = {contact}/>
                        </TableCell>                                                                                            
                        <TableCell align="left"
                          sx={
                            follower_namesurname === "First Record"
                              ? {
                                  fontWeight: 'bold',
                                  fontSize: '16px',
                                  color: 'purple'
                                }
                              : {}
                          }
                        >
                          {follower_namesurname}  
                        </TableCell>   
                        <TableCell align="right">
                          <IconButton 
                            size="large" 
                            color="inherit" 
                            onClick={(event) => {
                              handleOpenMenu(event, {idstudents, idstudent_payment_contract, email, dateofbirth, name, surname, contact, follower, follower_namesurname, 
                                graduation, memberships, coursename, status, graduation, resourcecode, allreferences, assessmentlevelname, 
                                description, interninfo, idbatch, langlevel, payment_contract_details})
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>                        
                      </TableRow>
                      {/* Details row */}
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                          <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
                              <Box margin={1}>
                              <Table size="small" aria-label="payment details">
                                <FinanceContractDetails
                                  isRowOpen={isRowOpen}
                                  name={name}
                                  surname={surname}
                                  contractdate={replaceDateTime(contractdate)}                        
                                  payment_contract_details={payment_contract_details}
                                  created_name_surname={created_name_surname} 
                                  contractId = {idstudent_payment_contract}
                                />
                              </Table>
                              </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                          <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
                              <Box margin={1}>
                              <Table size="small" aria-label="payment details">                             
                              <FinancePaymentDetails
                                isRowOpen = {isRowOpen}
                                name = {name}
                                surname = {surname}
                                contractdate = {replaceDateTime(contractdate)}                        
                                payment_contract_details = {payment_contract_details}
                                created_name_surname = {created_name_surname}
                                isFinanceRole = {isFinanceRole}
                                idstudent = {idstudents}
                              />
                              </Table>
                              </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>                                        
                    );                    
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}                  
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}                
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 205, //PopupMenu Weight
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label0} onClick={handleDownRecord} sx={{ color: 'text.dark', Width: 300}}> 
          <SvgIcon component={Icon_Attendance} sx={{ mr: 2 }} />
          {t('AssignStudent')}
        </MenuItem>

        <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'success.dark', Width: 300}}>          
            <TrendingUpIcon sx={{ mr: 2 }} />
        {t('FinancialTracking')}
        </MenuItem>

        <MenuItem key={MENU_OPTIONS.label1} onClick={handleEditStudent} sx={{ color: 'primary.main' , minWidth: 300}}>
          <Iconify icon={'eva:edit-2-fill'} sx={{ mr: 2 }} />
          {t('Edit')}
        </MenuItem>

        <MenuItem key={MENU_OPTIONS.label2} onClick={handleDownRecord} sx={{ color: 'error.main', minWidth: 300 }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('Cancel Status')}
        </MenuItem>
      </Popover>

      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} 
        sx={{ 
          width: 480, minWidth: 350, minheight: 100, height: 130, fontSize: 14, 
          fontStyle: 'normal',
          whiteSpace: 'pre-line' //  Burada \n yerine satir atlamak icin whiteSpace stilini uyguluyoruz.
        }} >
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {alertMessage}
          </Typography>
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The registration of the student(s) whose above information is visible will be deleted.
            <br/>
            Are you sure you want to do this?
            
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
          {t('Cancel')}
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            {t('Update')}
          </Button>
        </DialogActions>        
      </Dialog>        
      {/* <Dialog
        open={openConfirmExtractDialog}
        onClose={handleConfirmExtractDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The interest and registration of the student(s) whose information is shown above will be removed from all platforms.
          </p>
              <br/>
              Are you sure you want to do this?          
        </DialogContent>
        <DialogActions>
          <Button sx = {{ pr: 10 }} onClick={handleConfirmExractDialogClose} color="primary">
          {t('Cancel')}
          </Button>
          <Button 
            sx = {{ pr: 10, textAlign: 'center' }}
            onClick={handleConfirmExtract} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            {t('Update')}
          </Button>
        </DialogActions>        
      </Dialog> */}
      {/* {console.log('firstRecordStudentData.idstudent_payment_contract: ', firstRecordStudentData.idstudent_payment_contract)} */}
      {openAssignStudentExtractDialog && (
        
        <AssignStudentDialog 
          onTransactionAdded={handleNewStudentAdded} 
          studentNameSurname={{ Name: firstRecordStudentData.nameSurname }}
          studentId={firstRecordStudentData.id}
          email={firstRecordStudentData.email} 
          paymentDetails={firstRecordStudentData.paymentDetails}
          contractId = {firstRecordStudentData.contractId}
          openDialog={openAssignStudentExtractDialog} 
          onClose={handleFirstTaskDialogClose}
        /> 
      )}
    </>
  );
};

