import React, { useState, useEffect } from 'react';
import { Typography, Box, Stack, Collapse, TableRow, TableCell, 
  Divider, Grid, TableHead, TableBody } from '@mui/material';
import getStudentFinancialDatas from '../../_data/finance/getStudentFinancialDatas';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { useTranslation } from 'react-i18next'; //Multi Language

const FinancialPaymentDetails = ({ isRowOpen, name, surname,  isFinanceRole, idstudent }) => {
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [studentFinancialData, setStudentFinancialData] = useState([]);

  useEffect(() => {    
    getFinancialDatas(idstudent);
  }, [idstudent]);

  const getFinancialDatas = async (idstudent) => {
    try {
      showLoading();
      if (isFinanceRole) {
        const data = await getStudentFinancialDatas(idstudent);
        console.log("data", data);
        setStudentFinancialData(data);
      } else {
        // TODO: You do not have permission!
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    };
    // Cut T ve Z char
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 10);
    return formattedDate;
  };

  return (
    <>
    
      <TableHead>      
        <TableRow>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Payment Type</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Payment Frequency</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Amount</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Duedate</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Payment Date</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Paid Charge</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Status</TableCell>
          <TableCell sx={{fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {studentFinancialData?.length > 0 ? (          
          studentFinancialData?.map((item, index) => (
            <TableRow 
              key={index}
              style={{
                backgroundColor: item.status === 'PAID' ? '#fff9c4' : 'inherit',
              }}
            >
              <TableCell sx={{width: '12%', textAlign: 'center', verticalAlign: 'middle', color: item.paymenttypename === "Registiration Fee" ? 'green' : 'black'
                ,fontWeight: item.paymenttypename === "Registiration Fee" ? 'bold' : 'normal', borderRight: '1px solid #ccc'
              }}> {item.paymenttypename}</TableCell>
              <TableCell sx={{width: '10%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{item.paymentfrequency}</TableCell>
              <TableCell sx={{width: '8%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{item.currency} {item.amount}</TableCell>
              <TableCell sx={{width: '8%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{replaceDateTime(item.duedate)}</TableCell>
              <TableCell sx={{width: '8%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{replaceDateTime(item.paymentdate)}</TableCell>
              <TableCell sx={{width: '8%', color: item.paid_charge > 0 ? 'blue' : 'black'
                ,fontWeight: item.paid_charge > 0 ? 'bold' : 'normal', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'
              }}>{item.currency}{item.paid_charge}</TableCell>
              <TableCell sx={{width: '8%', color: item.status === "PAID" ? 'blue' : 'red'
                ,fontWeight: item.status === "PAID" ? 'bold' : 'normal', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid #ccc'
              }}>{item.status}</TableCell>
              <TableCell sx={{width: '38%', textAlign: 'left', verticalAlign: 'middle', borderRight: '1px solid #ccc'}}>{item.additionalInfo}</TableCell>
            </TableRow>
          ))
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                No financial data available
              </TableCell>
            </TableRow>
          )}
          { studentFinancialData?.length > 0 ? (
        <TableRow>
          <TableCell colSpan={4} style={{color: '#ff5722', fontWeight: 'bold'}}>
          <Typography variant="body1">
            <strong>NOTE:</strong> 
            <span style={{ color: 'black', fontWeight: 'bold'  }}> {studentFinancialData[0]?.description}</span>          
          </Typography>
          </TableCell>
        </TableRow>
      ): null}
        </TableBody>     
    </>
  );
};

export default FinancialPaymentDetails;