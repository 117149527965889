import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setStudentFinancialDatas = async (studentId, paymentDetails, paymentdate, paymentOverdueDate, paymentDescription, contractId) => {
  try {
      const token = await getToken();
      //console.log('setStudentFinancialDatas xxxx: ', studentId, paymentdate, paymentOverdueDate, paymentDescription, paymentDetails, contractId);
      const response = await axios.post(`${BaseUrl}/finance/setregiteredstudentfinancialdatas`, 
        {
          studentId: studentId,
          paymentDetails: paymentDetails,
          paymentdate: paymentdate,
          paymentOverdueDate: paymentOverdueDate,
          description: paymentDescription,
          contractId: contractId
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

export default setStudentFinancialDatas;
