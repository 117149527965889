import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getPaymentDefinitions= async (_coursefeeregiontype, _coursetype, _paymentfrq) => {
  try {
    const token = await getToken();
    //console.log('getPaymentDefinitions: ', _coursefeeregiontype, _coursetype, _paymentfrq);
    const response = await axios.get(`${BaseUrl}/coursecoordination/getpaymentdefinitions`, {    
      headers: {
        'Authorization': `Bearer ${token}`
      },     
      params: {
        coursefeeregiontype: _coursefeeregiontype, //Ulke kodu (int)
        coursetype: _coursetype,   //ONLINE (int)
        paymentfrq: _paymentfrq,  //CASH OR INSTALLMENT (string)
      }
    });
    return response.data;
  } catch (error) {  
      console.error("Report error: ", error);
      return [];
  }
};

export default getPaymentDefinitions;